import React from 'react';
import { Typography, Stack, Box } from '@mui/material';

import { AdBoardParticipantRole } from '@keyops-hcp/constants';

import i18next from '../../../../languages/i18n.config';
import { useDeclineAdBoardInviteMutation } from '../../../../hooks';
import { theme } from '../../../../theme';
import { PrimaryButton } from '../../../PrimaryButton';
import { TextButton } from '../../../TextButton';

export const AdBoardInviteChoiceBox = ({
  adBoardId,
  startDate,
  role,
  setOpenContractModal,
}: {
  adBoardId: string;
  startDate?: string;
  role: AdBoardParticipantRole;
  setOpenContractModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = i18next;
  const declineAdBoardInviteMutation = useDeclineAdBoardInviteMutation();

  const acceptInvite = () => {
    setOpenContractModal(true);
  };

  const declineInvite = () => {
    declineAdBoardInviteMutation.mutate(adBoardId);
  };

  return (
    <Stack direction='column' alignItems={'flex-start'}>
      <Stack
        direction='row'
        // justifyContent={'flex-start'}
        // flexWrap='wrap'
        pb={2}
        spacing={{ xs: 3, md: 4 }}
        width={'100%'}
      >
        <PrimaryButton
          onClick={acceptInvite}
          width={'fit-content'}
          minWidth={{ xs: '50%', md: '200px' }}
          height={{ xs: '48px', md: '72px' }}
          data-testid='adboard-invite-accept-btn'
        >
          <Typography
            variant='h2'
            color={'inherit'}
            fontSize={{ md: '16px', xs: '14px' }}
            minWidth={{ xs: '50%', md: '200px' }}
          >
            {t('ad_board.landing_page.cta.invited.register')}

            {startDate && (
              <Box fontSize={{ xs: '12px', md: '14px' }}>
                {t('ad_board.landing_page.starts', {
                  date: new Date(startDate).toLocaleDateString(
                    i18next.language,
                    {
                      month: 'short',
                      day: '2-digit',
                    }
                  ),
                })}
              </Box>
            )}
          </Typography>
        </PrimaryButton>
        <TextButton
          onClick={declineInvite}
          data-testid='adboard-invite-decline-btn'
        >
          <Typography
            variant='h2'
            color={'inherit'}
            sx={{
              fontSize: { md: '16px', xs: '14px' },
            }}
          >
            {t('ad_board.landing_page.cta.invited.decline')}
          </Typography>
        </TextButton>
      </Stack>
      <Typography variant='body3' color={theme.palette.keyops.gray[600]}>
        {t('ad_board.landing_page.cta.invited.helperText.' + role)}
      </Typography>
    </Stack>
  );
};
