import React from 'react';
import { useLocation } from 'react-router';
import { alpha, AppBar, useMediaQuery, useTheme } from '@mui/material';

import { MobileTopNavBar } from './MobileTopNavBar';
import { DesktopTopAppBar } from './DesktopTopAppBar';

export const CustomTopAppBar = ({
  toggleMobileBottomNavDrawer,
  scrolled,
  topNavBarHeight = 60,
}: {
  toggleMobileBottomNavDrawer: (newOpen: boolean) => void;
  scrolled: boolean; // Info needed for desktop top navbar
  topNavBarHeight?: number; // Info needed for desktop top navbar
}) => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const location = useLocation();
  const isKolabLandingPage = location.pathname.startsWith('/adboard-landing/');

  return (
    <AppBar
      data-testid="top-navbar"
      position={'fixed'}
      component="nav"
      sx={{
        bgcolor:
          isMobile || scrolled
            ? palette.keyops.white.main
            : isKolabLandingPage
            ? alpha(palette.keyops.blue.arcticBlue, 0.2)
            : 'transparent',
        boxShadow: isMobile ? '0px 1px 2px 0px rgba(0, 0, 0, 0.06)' : 'none',
        height: `${topNavBarHeight}px`,
        borderBottom:
          isMobile || !scrolled
            ? `none`
            : `1px solid ${palette.keyops.blue.lightSteelBlue20}`,
        pr: { md: 7 }, // TODO: adjust alignment in bigger screen
      }}
    >
      {isMobile ? (
        <MobileTopNavBar
          toggleMobileBottomNavDrawer={toggleMobileBottomNavDrawer}
        />
      ) : (
        <DesktopTopAppBar />
      )}
    </AppBar>
  );
};
