import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './en.json';

// TODO: add 'fr' when ready to support French
const options = {
  fallbackLng: 'en',
  supportedLngs: ['en'],
  debug: false,
  resources: {
    en: {
      components: enTranslation,
    },
  },
  ns: 'components',
  defaultNS: 'components',
  interpolation: {
    escapeValue: false, // React already does escaping
    // TODO: solve this error - Had to comment because of build error (You may need an additional loader to handle the result of these loaders.)
    // format: function (value: string, format: string) {
    //   if (format === 'capitalize') {
    //     return value.charAt(0).toUpperCase() + value.slice(1);
    //   }
    //   return value;
    // },
  },
  detection: {
    order: ['navigator'], // Order and from where user language should be detected
  },
};
const instance = i18next.createInstance();
instance.init(options);
// To disable a warning on import i18next
// eslint-disable-next-line import/no-named-as-default-member
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
instance.use(LanguageDetector);

instance.services.formatter.addCached('keyOpsCurrency', (lang, options) => {
  const formatter = new Intl.NumberFormat(lang, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return (value) => formatter.format(value);
});
export default instance;
