import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { analytics } from '../../../../utils/analytics';
import { useAcceptAdBoardInviteMutation } from '../../../../hooks';
import { theme } from '../../../../theme';
import { isLocalOrDev } from '../../../../utils/common-utils';

interface DocusignContractProps {
  adBoardTitle: string;
  adBoardId: string;
  adBoardInviteId: string;
  contractURL: string;
  setOpenContractModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsContractSigned: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DocusignContract: React.FC<DocusignContractProps> = ({
  adBoardTitle,
  adBoardId,
  adBoardInviteId,
  contractURL,
  setOpenContractModal,
  setIsContractSigned,
}) => {
  const acceptAdBoardInviteMutation = useAcceptAdBoardInviteMutation();

  // TODO: put in a const
  const integrationKey = '3988d5b9-c26a-441a-be9b-2703cfbe4ceb';

  const initializeDocuSign = () => {
    if ((window as any).DocuSign) {
      (window as any).DocuSign.loadDocuSign(integrationKey)
        .then((docusign: any) => {
          const signing = docusign.signing({
            url: contractURL,
            displayFormat: 'focused',
            // TODO: see if we want to customize that
            style: {
              branding: {
                primaryButton: {
                  backgroundColor: theme.palette.keyops.blue.keyopsBlue,
                  color: '#fff',
                },
              },
              signingNavigationButton: {
                finishText: 'Finish',
                position: 'bottom-center',
              },
            },
          });

          signing.on('sessionEnd', (event: any) => {
            if (event.sessionEndType === 'signing_complete') {
              setOpenContractModal(false);
              acceptAdBoardInviteMutation.mutate(adBoardId);

              //TODO: check if needed still with new state
              setIsContractSigned(true);
              analytics.track('AdBoard Contract Signed', {
                title: adBoardTitle,
                adBoardId,
                adBoardInviteId,
              });
            } else {
              console.error('Session ended, but signin is not complete', event);
            }
          });

          const agreementElement = document.getElementById(
            'docusign-agreement-container'
          );

          if (agreementElement) {
            signing.mount('#docusign-agreement-container');
          } else {
            console.error('Agreement element not found');
          }
        })
        .catch((error: any) => {
          console.error('DocuSign initialization failed:', error);
        });
    } else {
      console.warn('DocuSign library not found.');
    }
  };

  useEffect(() => {
    // Load the DocuSign JS library if not already loaded

    const src = isLocalOrDev()
      ? 'https://js-d.docusign.com/bundle.js'
      : 'https://js.docusign.com/bundle.js';
    if (!document.querySelector(`script[src="${src}"]`)) {
      const script = document.createElement('script');
      script.src = src;

      script.async = true;
      script.onload = () => initializeDocuSign();
      document.body.appendChild(script);
    } else {
      initializeDocuSign();
    }
  }, [integrationKey, contractURL]);

  return (
    <Box
      width='90%'
      height='90%'
      className='docusign-agreement-container'
      id='docusign-agreement-container'
    ></Box>
  );
};
