import React from 'react';
import { useTheme, Stack, Avatar, StackProps, Typography } from '@mui/material';

import i18next from '../../languages/i18n.config';
import { FilledBarChart, FilledMessage2 } from '../../icons';

export const EngagementTag = ({
  engagementType,
  ...props
}: {
  engagementType: 'survey' | 'kolab';
} & StackProps) => {
  const { palette } = useTheme();

  return (
    <Stack
      data-testid={`engagement-tag-${engagementType}`}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      spacing={1}
      {...props}
    >
      <Avatar
        sx={{
          bgcolor:
            engagementType === 'kolab'
              ? palette.keyops.teal
              : palette.keyops.blue.keyopsBlue,
        }}
      >
        {engagementType === 'kolab' ? (
          <FilledMessage2 sx={{ color: palette.keyops.white.main }} />
        ) : (
          <FilledBarChart
            sx={{
              color: palette.keyops.white.main,
              fontSize: { sm: '14px', md: '27px' },
            }}
          />
        )}
      </Avatar>
      <Typography variant='h2' textTransform={'uppercase'}>
        {i18next.t(`general.engagementType`, { engagementType })}
      </Typography>
    </Stack>
  );
};
