import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';

import { AdBoardDto } from '@keyops-hcp/dtos';

import i18next from '../../../../languages/i18n.config';
import { useStyles } from './styles';
import { HTMLOutput } from '../../../HTMLOutput';
import { AdBoardContent } from '../AdBoardContent';

interface AdBoardLandingPageTabsProps {
  adBoard: AdBoardDto;
}

export const AdBoardLandingPageTabs: React.FC<AdBoardLandingPageTabsProps> = ({
  adBoard,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const [view, setView] = useState('objectives');

  const tabs = [
    {
      value: 'objectives',
      label: i18next.t('ad_board.landing_page.objectives'),
    },
    { value: 'contents', label: i18next.t('ad_board.landing_page.contents') },
  ];
  const handleTabChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    setView(nextView);
  };

  return (
    <Box sx={{ px: 7, py: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={view} onChange={handleTabChange}>
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              aria-label={tab.label}
              sx={{
                ...styles.subTitle,
                color:
                  view === tab.value
                    ? theme.palette.keyops.blue.keyopsBlue
                    : theme.palette.keyops.black.main,
                fontSize: '20px',
              }}
            />
          ))}
        </Tabs>
      </Box>
      <Box sx={styles.content}>
        {view === 'objectives' && (
          <Typography>
            <HTMLOutput html={adBoard.objectives} />
          </Typography>
        )}
        {view === 'contents' && <AdBoardContent adBoardId={adBoard.id} />}
        {view === 'moderators' && (
          <Typography>
            <HTMLOutput html={adBoard.moderators} />
          </Typography>
        )}
      </Box>
    </Box>
  );
};
