import React from 'react';
import { FiX } from 'react-icons/fi';
import { Modal, Box, useTheme, Typography, Stack } from '@mui/material';

import i18next from '../../../../languages/i18n.config';
import { useGetContractURLByInviteId } from '../../../../hooks';
import { DocusignContract } from '../DocusignContract';
import { KeyopsHeader2 } from '../../../Headers';

export const AdBoardContractModal = ({
  participantName,
  adBoardTitle,
  adBoardId,
  adBoardInviteId,
  isContractModalOpen,
  setOpenContractModal,
}: {
  participantName: string;
  adBoardTitle: string;
  adBoardId: string;
  adBoardInviteId: string;
  isContractModalOpen: boolean;
  setOpenContractModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isContractSigned, setIsContractSigned] =
    React.useState<boolean>(false);

  // Fetch the contractURL before opening the modal to optimize loading time
  const {
    data: contractURL,
    isLoading,
    isFetching,
  } = useGetContractURLByInviteId(
    adBoardId,
    adBoardInviteId,
    !isContractSigned
  );

  const { t } = i18next;
  const theme = useTheme();

  return (
    <Modal
      id='ad-board-contract-modal'
      data-testid='ad-board-contract-modal'
      open={isContractModalOpen}
      onClose={() => setOpenContractModal(false)}
      aria-labelledby='ad-board-contract-modal'
      aria-describedby='ad-board-contract-modal'
      sx={{
        width: '90vw',
        height: '90vh',
        zIndex: 3000,
        margin: 'auto',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          width: '100%',
          height: '100%',
          borderRadius: '8px',
          overflow: 'scroll',
        }}
      >
        <Stack
          direction='row'
          alignItems={'center'}
          justifyContent={'space-between'}
          padding='16px'
          borderBottom={`1px solid ${theme.palette.keyops.blue.lightSteelBlue}4D`}
        >
          <Stack direction='column' spacing={1}>
            <KeyopsHeader2>
              {participantName} - {adBoardTitle}
            </KeyopsHeader2>
            <Typography
              variant='body2'
              sx={{ color: theme.palette.keyops.black.main }}
            >
              {t('ad_board.landing_page.contract.helperText')}
            </Typography>
          </Stack>
          <Box width={'24px'} height={'24px'}>
            <FiX
              onClick={() => setOpenContractModal(false)}
              fontSize={'16px'}
              color={theme.palette.keyops.blue.lightSteelBlue}
              style={{
                cursor: 'pointer',
              }}
            />
          </Box>
        </Stack>

        <Stack
          direction='column'
          alignItems={'center'}
          justifyContent={'center'}
          width='100%'
          height='100%'
          sx={{ background: theme.palette.keyops.white.main }}
        >
          {contractURL && (!isLoading || !isFetching) ? (
            <DocusignContract
              adBoardId={adBoardId}
              adBoardTitle={adBoardTitle}
              adBoardInviteId={adBoardInviteId}
              contractURL={contractURL}
              setOpenContractModal={setOpenContractModal}
              setIsContractSigned={setIsContractSigned}
            />
          ) : (
            // Since Docusign has its own loader, better use a helper text here
            t('ad_board.landing_page.contract.loadingText')
          )}
        </Stack>
      </Box>
    </Modal>
  );
};
