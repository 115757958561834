import React from 'react';
import { Button, Box, useTheme } from '@mui/material';
import { BaseElementProps } from '../types';

// TODO: reconcile with Primary Button
export const AdBoardButton = ({ children, ...props }: BaseElementProps) => {
  const { palette } = useTheme();
  return (
    <Button
      sx={{
        width: props.width ?? '100%',
        height: props.height ?? '40px',
        backgroundColor: palette.keyops.blue.keyopsBlue,
        '&:hover': {
          backgroundColor: palette.keyops.blue.midnightBlue,
        },
      }}
      variant='contained'
      disableRipple
      disableElevation
      {...props}
    >
      <Box
        sx={{
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {children}
      </Box>
    </Button>
  );
};
