// Auth Routes
export const ROOT = `/`;
export const LOGIN = `/login`;
export const LOGOUT = `/logout`;
export const FORGOT_PASSWORD = `/forgot-password`;
export const FORGOT_EMAIL = `/forgot-email`;
export const SETUP_PASSWORD = `/setup-password/:resetToken`;
export const RESET_PASSWORD = `/reset/:resetToken`;
export const VERIFY_WORK_EMAIL = `VerifyWorkEmail/:verifyToken`;
export const NOT_FOUND = `/*`;

// Onboarding Routes
export const ONBOARDING = `onboarding`;
export const ONBOARDING_PATH = `/${ONBOARDING}`;
export const ONBOARDING_ID = `:onboardingId`;
export const ONBOARDING_ROOT = `${ONBOARDING_PATH}/${ONBOARDING_ID}`;

// Onboarding Landing Routes
export const ONBOARDING_LANDING_ROOT = `onboardlanding`;
export const ONBOARDING_LANDING_PATH = `/${ONBOARDING_LANDING_ROOT}`;
export const ONBOARDING_LANDING = `${ONBOARDING_LANDING_PATH}/${ONBOARDING_ID}`;

// Main Pages Routes
export const DASHBOARD = `/dashboard`;
export const EARNINGS = `/earnings`;
export const PROFILE = `/profile`;

// Engagement Routes
export const ENGAGEMENT_ROOT = `/engagement`;
export const ENGAGEMENT = `${ENGAGEMENT_ROOT}/:engagementId`;
export const ENGAGEMENT_DISPLAY_ROOT = `/engagementDisplay`;
export const ENGAGEMENT_DISPLAY = `${ENGAGEMENT_DISPLAY_ROOT}/:engagementId`;

// Referral Routes
export const REFERRAL_ROOT = `/referral`;
export const REFERRAL = `${REFERRAL_ROOT}/:referralId`;
export const ACCEPT_REFERRAL = `/accept-referral`;

// Surveys Routes DEPRECATED
export const SURVEYS = `/surveys`;
export const SURVEY_ID = `:surveyId`;
export const SURVEY_DONE = `/survey-done/:engagementId`;

// Rewards Routes
export const REWARDS = `/rewards`;

// AdBoard Landing Routes
export const ADBOARD_LANDING_ROOT = `/adboard-landing`;
export const ADBOARD_LANDING = `${ADBOARD_LANDING_ROOT}/:adBoardId`;

// AdBoard Routes
export const ADBOARD_ROOT = `/adboard/:adBoardId`;
export const ADBOARD_SECTIONS = `${ADBOARD_ROOT}/sections/:sectionId?/:contentId?`;

// External sites
export const REWARDS_MARKETING = `https://keyops.io/rewards`;
export const PERKOPOLIS = `https://www.perkopolis.com/`;
export const PARKNFLY = `https://www.parknfly.ca/about-parknfly/`;
export const PARKNFLY_BOOKING = `https://www.parknfly.ca/location/toronto-airport-parking/?referralID=17219&coupon=1135949`;
export const WAIVETHEWAIT = `https://www.waivethewait.ca/`;

//Legal stuff
export const LEGAL = `/legal`;
