import React, { MouseEvent, useState } from 'react';

import { useNotifications } from '../../hooks';
import { NotificationsButton } from '../NotificationsButton';
import { ResponsiveNavBarContent } from '../ResponsiveNavBarContent';
import { NotificationsRenderer } from '../NotificationsRenderer';

export const Notifications = () => {
  // notification popover config
  const [notificationAnchor, setNotificationAnchor] =
    useState<HTMLButtonElement | null>(null);
  const toggleNotificationsUI = (event: MouseEvent<HTMLButtonElement>) => {
    setNotificationAnchor(event.currentTarget);
  };
  const handleNotificationsUIClose = () => {
    updateNotificationsReadStatus();
    setNotificationAnchor(null);
  };
  const isNotificationUIOpen = Boolean(notificationAnchor);

  const {
    list: notificationsList,
    unread: areNotificationsUnread,
    updateReadStatus: updateNotificationsReadStatus,
    loading: loadingNotifications,
    error: notificationsError,
  } = useNotifications();

  return (
    <>
      <NotificationsButton
        toggleNotificationsUI={toggleNotificationsUI}
        areNotificationsUnread={areNotificationsUnread}
      />

      <ResponsiveNavBarContent
        contentType='notifications'
        isOpen={isNotificationUIOpen}
        onOpen={toggleNotificationsUI}
        onClose={handleNotificationsUIClose}
        contentAnchor={notificationAnchor}
        minHeight={240}
        maxHeight={`80vh`}
      >
        <NotificationsRenderer
          handleClose={handleNotificationsUIClose}
          notificationsList={notificationsList}
          loadingNotifications={loadingNotifications}
          notificationsError={notificationsError}
          // TODO: add a toggle all notifs
        />
      </ResponsiveNavBarContent>
    </>
  );
};
