import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import { MessageDto } from '@keyops-hcp/dtos';
import { DiscussionMessage } from '../DiscussionMessage';
import { fetchUrlHashData, scrollToMsgId } from '../../utils/discussion';

interface DiscussionChatMessagesProps {
  isThread?: boolean;
  pageDisplay?: boolean;
  messages: MessageDto[];
  shouldScrollToBottom?: boolean; // Add this prop to control scrolling
}

export const DiscussionChatMessages = ({
  isThread,
  pageDisplay,
  messages,
  shouldScrollToBottom,
}: DiscussionChatMessagesProps): JSX.Element => {
  const location = useLocation();
  const boxRef = useRef<HTMLDivElement>(null);

  // Scroll to the bottom only when shouldScrollToBottom is true
  useEffect(() => {
    if (boxRef.current && shouldScrollToBottom && !isThread) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }
  }, [shouldScrollToBottom]);

  const { msgId } = fetchUrlHashData();

  useEffect(() => {
    if (msgId) {
      scrollToMsgId(msgId);
    }
  }, [msgId, location]);

  return (
    <Box
      display='flex'
      flexDirection={pageDisplay ? 'column' : 'column-reverse'}
      gap={pageDisplay ? 2 : 0}
      alignContent='flex-start'
      ref={boxRef}
      flexGrow={1}
      overflow='auto'
      p={isThread ? '16px 0 0 0' : '16px 0'}
    >
      {messages?.map((message) => (
        <DiscussionMessage
          key={message.id}
          highlighted={msgId === message.id}
          message={message}
        />
      ))}
    </Box>
  );
};
