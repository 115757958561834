import React, { useState, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';

import { DiscussionMessageForm } from '../DiscussionMessageForm';
import { DiscussionChatMessages } from '../DiscussionChatMessages';
import i18next from '../../languages/i18n.config';
import { DiscussionDto } from '@keyops-hcp/dtos';

export const GeneralDiscussionChat = ({
  generalDiscussion,
}: {
  generalDiscussion: DiscussionDto;
}): JSX.Element => {
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);

  // Function to trigger scroll
  const scrollToMessages = () => {
    setShouldScrollToBottom(true);
  };

  // Reset shouldScrollToBottom after it has been used to avoid repeated scrolling
  useEffect(() => {
    if (shouldScrollToBottom) {
      setShouldScrollToBottom(false);
    }
  }, [shouldScrollToBottom]);

  return (
    <Stack
      direction='column'
      height='100%'
      overflow='hidden' // Prevent scrolling here
      justifyContent='space-between'
      p={`0px 58px`}
      pb={2}
    >
      <DiscussionChatMessages
        shouldScrollToBottom={shouldScrollToBottom}
        messages={generalDiscussion?.messages}
      />
      {/* Display a helper message for general discussion without any messages*/}
      {generalDiscussion?.messages.length === 0 && (
        <Typography
          textAlign='center'
          fontSize={`16px`}
          color={'keyops.gray.600'}
          pb='8px'
        >
          {i18next.t('ad_board.discussion.generalDiscussion.helperMessage')}
        </Typography>
      )}
      <DiscussionMessageForm
        onPost={() => scrollToMessages()}
        discussionId={generalDiscussion?.id}
      />
    </Stack>
  );
};
