import React from 'react';
import { useTheme, Card } from '@mui/material';
import { useStyles } from './styles';
import { BaseElementProps } from '../../../types';

export const DetailsCard = ({ children, ...props }: BaseElementProps) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Card variant='outlined' sx={styles.card} {...props}>
      {children}
    </Card>
  );
};
