import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import { InvitationState } from '@keyops-hcp/constants';
import {
  HTMLOutput,
  KeyopsHeader1,
  EngagementDetailsBox,
  EngagementTag,
} from '@keyops-hcp/ui-components';

import { EngagementEligibilityConfirmation } from './EngagementEligibilityConfirmation';

export const PreEngagementPage = ({
  title,
  payoutValue,
  points,
  summary,
  estimatedTime,
  state,
  hasOtherEligibilityCriteria,
  otherEligibilityCriteria,
  startEngagement,
  declineEngagement,
}: {
  title: string;
  payoutValue: number;
  points: number;
  summary: string;
  estimatedTime: number;
  state: InvitationState;
  hasOtherEligibilityCriteria: boolean;
  otherEligibilityCriteria: string;
  startEngagement: () => void;
  declineEngagement: () => void;
}) => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const getButtonLabel = () => {
    if (state === 'in_progress') {
      return t('engagement.resumeButtonLabel');
    }
    return t('engagement.startButtonLabel');
  };

  useEffect(() => {
    document.body.style.backgroundColor =
      theme.palette.keyops.blue.arcticBlue30;
    return () => {
      document.body.style.backgroundColor = theme.palette.keyops.white.main;
    };
  }, []);

  const theme = useTheme();

  return (
    <Box>
      <EngagementEligibilityConfirmation
        startOpen={hasOtherEligibilityCriteria && state === 'new'}
        otherEligibilityCriteria={otherEligibilityCriteria}
        declineEngagement={declineEngagement}
      />

      <EngagementTag engagementType="survey" pb={3} />

      <Stack direction={isMobile ? `column` : `row`} spacing={3}>
        <Box>
          {/** Title */}
          <KeyopsHeader1>{title}</KeyopsHeader1>
          {/** Content */}
          {summary && (
            <Typography>
              <HTMLOutput html={summary} />
            </Typography>
          )}
        </Box>
        <EngagementDetailsBox
          duration={estimatedTime}
          honorarium={payoutValue}
          points={points}
          ctaAction={() => {
            startEngagement();
          }}
          ctaLabel={getButtonLabel()}
        />
      </Stack>
    </Box>
  );
};
