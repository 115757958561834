import React, { useContext, useEffect } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';

import { UserDto } from '@keyops-hcp/dtos';
import {
  hoverItemStyle,
  PrimaryTooltip,
  useEarningsByUserId,
  Wallet,
} from '@keyops-hcp/ui-components';

import { UserContext } from '../../../UserContext';
import { getTotalEarnings } from '../../../utils/functions/earning-utils';
import i18n from '../../../languages/i18n.config';

export const EarningsNavSection = (): JSX.Element => {
  const { palette } = useTheme();

  // TODO: rework
  const context = useContext(UserContext);
  const [userData, setUserData] = React.useState<UserDto>({} as UserDto);
  useEffect(() => {
    context.getData({
      setUserData,
    });
  }, [context]);

  const { data: totalEarnings, isLoading } = useEarningsByUserId(userData?.id);

  // TODO: loading state for this?
  if (isLoading || userData.hcpType === 'moderator') return <></>;

  return (
    <PrimaryTooltip title={i18n.t('navbar.navItems.hover.earnings')}>
      <Stack
        data-testid="earnings-nav-section"
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        spacing={1}
        p={1}
        sx={{ ...hoverItemStyle() }}
      >
        <Wallet
          sx={{ color: palette.keyops.blue.midnightBlue, fontSize: '22px' }}
        />
        <Typography
          fontSize={'16px'}
          fontWeight={400}
          color={palette.keyops.black.main}
        >
          $
          {Intl.NumberFormat('currency').format(
            getTotalEarnings(totalEarnings)
          )}
        </Typography>
      </Stack>
    </PrimaryTooltip>
  );
};
