import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getAdBoardContractUrl } from '../api/adBoardInvites';

export const useGetContractURLByInviteId = (
  adBoardId: string,
  inviteId: string,
  enabled: boolean = true // Add `enabled` parameter for conditional fetching
): UseQueryResult<string, Error> => {
  return useQuery({
    queryKey: ['getAdBoardContractUrl', adBoardId, inviteId],
    queryFn: () => getAdBoardContractUrl(adBoardId, inviteId),
    enabled: enabled && !!adBoardId && !!inviteId,
    onError: (error: Error) => {
      // TODO: add a toast
      console.error(error);
    },
  });
};
