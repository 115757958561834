import React from 'react';
import { FiCheckCircle } from 'react-icons/fi';

import { Stack, Typography, useTheme } from '@mui/material';

import i18next from '../../../../languages/i18n.config';

export const AdBoardUpcomingBox = ({ startDate }: { startDate: string }) => {
  const { t } = i18next;
  const theme = useTheme();

  const startDateDisplay = startDate
    ? new Date(startDate).toLocaleDateString(i18next.language, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })
    : undefined;

  // TODO: Once the user closes the modal, can we think of a cool transition to this? Some sort of animation to bring attn to it?
  return (
    <Stack direction='column' spacing={1}>
      <Stack direction='row' alignItems={'center'} spacing={1}>
        <FiCheckCircle
          color={theme.palette.keyops.teal}
          fontSize={'16px'}
          style={{
            flexShrink: 0,
          }}
        />
        <Typography variant='h2'>
          {t('ad_board.landing_page.cta.upcoming.title')}
        </Typography>
      </Stack>

      <Typography variant='subtitle1' color={theme.palette.keyops.black.main}>
        {startDateDisplay
          ? t('ad_board.landing_page.cta.upcoming.copy', {
              startDate: startDateDisplay,
            })
          : t('ad_board.landing_page.cta.upcoming.copy_no_date')}
      </Typography>
    </Stack>
  );
};
