import { Theme } from '@mui/material';

export const useStyles = (theme: Theme) => ({
  subTitle: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '32px',
    color: theme.palette.keyops.black.main,
  },
  content: {
    pt: 3,
    display: 'block',
    width: '90%',
    margin: '0 auto',
  },
});
