import React, { MouseEvent, useContext, useEffect, useState } from 'react';
import {
  alpha,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FiChevronDown } from 'react-icons/fi';

import {
  formatAuthorName,
  hoverItemStyle,
  KeyOpsAvatar,
} from '@keyops-hcp/ui-components';
import { UserDto } from '@keyops-hcp/dtos';

import { UserContext } from '../../../UserContext';
import { Profile } from '../components/Profile/components/Profile';
import { ResponsiveNavBarContent } from './ResponsiveNavBarContent';

export const ProfileNavSection = (): JSX.Element => {
  const { breakpoints, palette } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  // Handle profile popover
  const [profileAnchor, setProfileAnchor] = useState<
    HTMLButtonElement | SVGSVGElement | null
  >(null);

  // Set anchor point to the button element to position the popover.
  const toggleProfile = (
    event: MouseEvent<HTMLButtonElement | SVGSVGElement>
  ) => {
    // Set the anchor at the chevron icon level
    const svgElement = event.currentTarget.querySelector('svg');
    svgElement
      ? setProfileAnchor(svgElement)
      : setProfileAnchor(event.currentTarget);
  };
  const isProfileOpen = Boolean(profileAnchor);

  // Handle user data
  // TODO: rework
  const context = useContext(UserContext);
  const [userData, setUserData] = React.useState<UserDto | undefined>(
    undefined
  );
  const [loadingData, setLoadingData] = React.useState(true);

  useEffect(() => {
    context.getData({
      setUserData,
      setLoadingData: setLoadingData,
    });
  }, [context]);

  return (
    <Stack
      direction={'row'}
      alignContent={'center'}
      justifyContent={'space-between'}
      spacing={1}
      pr={0}
    >
      <Button
        data-testid="profile-drawer-button"
        sx={{
          ...hoverItemStyle(),
          pr: 0,
          bgcolor: isProfileOpen
            ? alpha(palette.keyops.blue.arcticBlue, 0.2)
            : 'inherit',
          color: isProfileOpen ? palette.keyops.blue.keyopsBlue : 'inherit',
        }}
        onClick={toggleProfile}
        disableRipple
      >
        {userData && (
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <KeyOpsAvatar
              text={formatAuthorName(
                [userData.firstName, userData.lastName],
                true
              )}
              width={'32px'}
              height={'32px'}
              fontSize={isMobile ? '14px' : '16px'}
              fontWeight={400}
            />
            {!isMobile && (
              <>
                <Typography color={palette.keyops.black.main} fontWeight={400}>
                  {userData.firstName + ' ' + userData.lastName}
                </Typography>

                <FiChevronDown
                  color={palette.keyops.blue.lightSteelBlue}
                  style={{
                    fontSize: 20,
                  }}
                />
              </>
            )}
          </Stack>
        )}
      </Button>
      {/* The content is displayed either in a drawer (mobile) or a popover */}
      <ResponsiveNavBarContent
        contentType="profile"
        isOpen={isProfileOpen}
        onOpen={() => undefined}
        onClose={() => setProfileAnchor(null)}
        contentAnchor={profileAnchor}
        topOffset={`16px`}
        minHeight={`80vh`}
        maxHeight={`80vh`}
      >
        <Profile userData={userData} isLoading={loadingData} />
      </ResponsiveNavBarContent>
    </Stack>
  );
};
