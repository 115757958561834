import React from 'react';
import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router';

/**
 * CentralContainerLayout is the nested, secondary layout, used in router.tsx
 * It contains the main content of a page and can be customized
 *  - Set padding to 0 to override the default ones
 *  - Set maxWidth to false to make the container spans the full width
 */
export const CentralContainerLayout = ({
  topNavBarHeight = 60,
  ...props
}): JSX.Element => {
  return (
    <Container
      id="central-container-layout"
      data-testid="central-container-layout"
      maxWidth={props.maxWidth ?? 'customMd'}
      disableGutters
      sx={{
        overflow: 'hidden',
      }}
    >
      <Box
        pt={props.pt ?? { xs: 4, md: 1 }}
        pb={props.pb ?? 2}
        pl={props.pl ?? { xs: 2, sm: 6, md: 8 }}
        pr={props.pr ?? { xs: 2, sm: 6, md: 8 }}
        // Handling of the scroll down
        mb={{ xs: topNavBarHeight * 2 + 'px', md: topNavBarHeight + 'px' }}
        overflow={'hidden'}
      >
        <Outlet />
      </Box>
    </Container>
  );
};
