import React from 'react';
import { Box } from '@mui/material';
import { UserDisplayDto } from '@keyops-hcp/dtos';

import { AdBoardLandingPageProps } from './AdBoardLandingPage.types';
import i18next from '../../../languages/i18n.config';
import { useAdBoardInviteByAdBoardId } from '../../../hooks';
import { analytics } from '../../../utils/analytics';
import { AdBoardContractModal } from './AdBoardContractModal';
import { getUserDisplayName } from '../../../utils/user';
import { AdBoardLandingPageTabs } from './AdBoardLandingPageTabs';
import { AdBoardLandingHeader } from './AdBoardLandingHeader';
import { AdBoardDeclined } from './AdBoardDeclined';

export const AdBoardLandingPage: React.FC<AdBoardLandingPageProps> = ({
  adBoardId,
}) => {
  const {
    data: adBoardInvite,
    isLoading,
    error,
  } = useAdBoardInviteByAdBoardId(adBoardId);

  // In-app contract handling
  const [isContractModalOpen, setOpenContractModal] =
    React.useState<boolean>(false);

  if (error) return <div>{i18next.t('common.error')}</div>;
  if (isLoading || !adBoardInvite || !adBoardInvite.adBoard) {
    return <div>{i18next.t('common.loading')}</div>; // TODO: use loading component & update test
  }

  const adBoard = adBoardInvite.adBoard;

  // Analytics
  analytics.track('AdBoard Landing Page', {
    title: adBoard.title,
    adBoardId,
    adBoardState: adBoard.state,
    inviteState: adBoardInvite.state,
  });

  if (adBoardInvite.state === 'declined') {
    return <AdBoardDeclined adBoardTitle={adBoard.title} />;
  }

  return (
    <Box p={0}>
      <AdBoardLandingHeader
        adBoardInvite={adBoardInvite}
        setOpenContractModal={setOpenContractModal}
      />

      {/* Tabs */}
      <AdBoardLandingPageTabs adBoard={adBoard} />

      {adBoardInvite.state === 'sent' && (
        <AdBoardContractModal
          participantName={getUserDisplayName(
            adBoardInvite.invitee as unknown as UserDisplayDto
          )}
          adBoardTitle={adBoardInvite.adBoard?.title}
          adBoardId={adBoardInvite.adBoardId}
          adBoardInviteId={adBoardInvite.id}
          isContractModalOpen={isContractModalOpen}
          setOpenContractModal={setOpenContractModal}
        />
      )}
    </Box>
  );
};
