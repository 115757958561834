import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { BaseElementProps } from '../types';

export const StyledButton = styled(Button)`
  text-transform: none;
`;

export const SecondaryButton = ({ children, ...props }: BaseElementProps) => {
  return (
    <StyledButton variant='outlined' {...props}>
      {children}
    </StyledButton>
  );
};
