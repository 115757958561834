import React from 'react';
import { Button } from '@mui/material';
import { useTheme } from '@mui/system';

import { BaseElementProps } from '../types';

export const PrimaryButton = ({ children, ...props }: BaseElementProps) => {
  const { palette } = useTheme();

  return (
    <Button
      variant='contained'
      sx={{
        width: props.width ?? '100%',
        minWidth: props.minWidth ?? '100%',
        height: props.height ?? '40px',
        px: props.px ?? 4,
        borderRadius: '6px',
        backgroundColor: palette.keyops.blue.keyopsBlue,
        '&:hover': {
          backgroundColor: palette.keyops.blue.midnightBlue,
        },
      }}
      disableRipple
      disableElevation
      {...props}
    >
      {children}
    </Button>
  );
};
