import React from 'react';
import { alpha, Container, Stack, Typography, useTheme } from '@mui/material';
import { Trans } from 'react-i18next';
import i18next from '../../../../languages/i18n.config';
import { PrimaryLink } from '../../../PrimaryLink';
import { BackToDashboardButton } from '../../../BackToDashboardButton';

export const AdBoardDeclined = ({ adBoardTitle }: { adBoardTitle: string }) => {
  const theme = useTheme();
  const { t } = i18next;

  return (
    <Stack
      p={0}
      sx={{ backgroundColor: alpha(theme.palette.keyops.blue.arcticBlue, 0.2) }}
      height={{ xs: '100vh', md: 'auto' }}
      overflow={'hidden'}
    >
      <BackToDashboardButton title={t('common.home')} showBackButton={true} />
      <Container
        maxWidth='customMd'
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Stack direction='column' spacing={2} py={8}>
          <Typography variant='body2' color={theme.palette.keyops.black.main}>
            {t('ad_board.landing_page.cta.declined.title')}
          </Typography>
          <Typography variant='h1'>{adBoardTitle}</Typography>
          <Typography variant='body2' color={theme.palette.keyops.gray[700]}>
            <Trans
              t={t}
              i18nKey='components:ad_board.landing_page.cta.declined.copy'
              components={{
                supportLink: (
                  <PrimaryLink href='mailto:physiciansupport@keyops.io' />
                ),
              }}
            />
          </Typography>
        </Stack>
      </Container>
    </Stack>
  );
};
