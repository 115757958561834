import React from 'react';
import { alpha, Box, Stack, Typography, Divider, Grid } from '@mui/material';
import { SxProps } from '@mui/system';
import { DetailsCard } from '../AdBoard/AdBoardLandingPage/DetailsCard';
import { EngagementEarningsDisplay } from '../EngagementEarningsDisplay';
import { theme } from '../../theme';
import { Calendar, ClockCircle } from '../../icons';
import i18next from '../../languages/i18n.config';
import { DateRange } from '../DateRange';
import { AdBoardButton } from '../AdBoardButton';

const iconStyle: SxProps = {
  color: theme.palette.keyops.teal,
  width: '20px',
  height: '20px',
};

const engagementDetail: SxProps = {
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '16px',
};
const engagementDetailValue: SxProps = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
};

const EngagementDetail = ({
  icon,
  name,
  value,
}: {
  icon: JSX.Element;
  name: string;
  value: JSX.Element;
}) => {
  return (
    <Stack
      direction={{ xs: 'row', md: 'column' }}
      alignItems={'flex-start'}
      spacing={{ xs: 0, md: 1 }}
    >
      <Stack direction='row'>
        {/* Icon Section */}
        <Box display={'flex'} alignItems={'center'} width={'28px'}>
          {icon}
        </Box>

        {/* Name Section - Hidden on Mobile */}
        <Box
          display={{ xs: 'none', sm: 'none', md: 'block' }} // Hidden on mobile
          alignContent='center'
        >
          <Typography sx={engagementDetail}>{name}</Typography>
        </Box>
      </Stack>

      {/* Value Section */}
      <Typography sx={engagementDetailValue}>{value}</Typography>
    </Stack>
  );
};

export const EngagementDetailsBox = ({
  honorarium,
  points,
  startDate,
  endDate,
  duration,
  ctaLabel,
  ctaAction,
}: {
  honorarium: number;
  points: number;
  startDate?: string;
  endDate?: string;
  duration?: number;
  ctaLabel?: string;
  ctaAction?: () => void;
}) => {
  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '100%', md: '314px' },
        maxWidth: { xs: '100%', sm: '100%', md: '314px' },
        minWidth: { xs: '100%', sm: '100%', md: '314px' },
        mt: { xs: 1, sm: 1, md: 0 },
      }}
    >
      <DetailsCard>
        <EngagementEarningsDisplay
          honorarium={honorarium}
          points={points}
          sx={{
            backgroundColor: alpha(theme.palette.keyops.blue.arcticBlue, 0.3),
          }}
        />

        <Stack direction={'column'} spacing={2} pt={2}>
          <Grid container spacing={0.5}>
            {/* Duration */}
            {!!duration && (
              <EngagementDetail
                icon={<ClockCircle sx={iconStyle} />}
                name={i18next.t('engagementDetails.duration')}
                value={
                  <>
                    {i18next.t('engagementDetails.durationDisplay', {
                      count: duration,
                    })}
                  </>
                }
              />
            )}
            <Grid xs={12} sx={{ height: '8px' }}></Grid>
            {/* Dates */}
            {startDate && endDate && (
              <EngagementDetail
                icon={<Calendar sx={iconStyle} />}
                name={i18next.t('engagementDetails.dates')}
                value={<DateRange startDate={startDate} endDate={endDate} />}
              />
            )}
          </Grid>
          {ctaAction && (
            <Box>
              <Divider sx={{ mb: 3 }} />
              <AdBoardButton onClick={ctaAction} height='56px'>
                {ctaLabel}
              </AdBoardButton>
            </Box>
          )}
        </Stack>

        {/* TODO: add helper msg */}
      </DetailsCard>
    </Box>
  );
};
