import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Link, Typography } from '@mui/material';

import { BackToDashboardButton } from '@keyops-hcp/ui-components';

import { DASHBOARD } from '../../../utils/routes';

const NotFoundVisual = ({
  showBackButton = true,
}: {
  showBackButton?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Container id="not-found" maxWidth={false}>
      {/** Back to Dashboard button */}
      <BackToDashboardButton showBackButton={showBackButton} />

      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 20,
        }}
      >
        <Typography
          variant="h6"
          textAlign={'center'}
          fontWeight={500}
          gutterBottom
        >
          {t('notFound.title')}
        </Typography>
        <Typography
          variant="body1"
          component={'p'}
          textAlign={'center'}
          px={2}
          gutterBottom
        >
          <Trans
            defaults={t('notFound.subtitle')}
            components={{
              linkTag: <Link href={DASHBOARD} underline="none" />,
            }}
          />
        </Typography>
      </Container>
    </Container>
  );
};

export default NotFoundVisual;
