import React, { useContext, useEffect, useState } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';

import {
  hoverItemStyle,
  isEligibleForRewards,
  PrimaryTooltip,
  StarCircle,
} from '@keyops-hcp/ui-components';
import { UserDto } from '@keyops-hcp/dtos';

import { UserContext } from '../../../UserContext';
import i18n from '../../../languages/i18n.config';

export const RewardsNavSection = (): JSX.Element => {
  const { palette } = useTheme();

  // TODO: rework
  const context = useContext(UserContext);

  const [userData, setUserData] = React.useState<UserDto>({} as UserDto);
  const [rewardPeriodData, setRewardPeriodData] = useState({
    pointsTotal: 0,
  });
  useEffect(() => {
    context.getData({
      setRewardPeriodData,
      setUserData,
    });
  }, [context]);

  // TODO: loading state for this?
  if (!isEligibleForRewards(userData.hcpType)) return <></>;

  return (
    <PrimaryTooltip title={i18n.t('navbar.navItems.hover.rewards')}>
      <Stack
        data-testid="rewards-nav-section"
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        spacing={1}
        p={1}
        sx={{ ...hoverItemStyle() }}
      >
        <StarCircle sx={{ color: palette.keyops.blue.midnightBlue }} />
        <Typography
          fontSize={'16px'}
          fontWeight={400}
          color={palette.keyops.black.main}
        >
          {Intl.NumberFormat('currency').format(rewardPeriodData?.pointsTotal)}
        </Typography>
      </Stack>
    </PrimaryTooltip>
  );
};
