import { Theme } from '@mui/material';

export const useStyles = (theme: Theme) => ({
  card: {
    p: { xs: 2, md: 3 },
    minWidth: '30%',
    borderRadius: 2,
    border: `0.5px solid ${theme.palette.keyops.blue.lightSteelBlue20}`,
    boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)`,
  },
});
