import React from 'react';
import { Button } from '@mui/material';
import { useTheme } from '@mui/system';

import { BaseElementProps } from '../types';

export const TextButton = ({ children, ...props }: BaseElementProps) => {
  const { palette } = useTheme();

  const mergedSx = {
    color: palette.keyops.blue.keyopsBlue,
    '&:hover': {
      color: palette.keyops.blue.midnightBlue,
      backgroundColor: 'transparent',
    },
    // Allow parent sx to override or add new styles
    ...props.sx,
  };

  return (
    <Button
      variant='text'
      sx={mergedSx}
      disableRipple
      disableElevation
      {...props}
    >
      {children}
    </Button>
  );
};
