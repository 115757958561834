import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Link,
  Stack,
  SwipeableDrawer,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { FiX } from 'react-icons/fi';

import { useContactUI } from '../../higher-order-components/ContactUIContext';
import Loading from '../Loading';
import { KeyopsHeader1 } from '@keyops-hcp/ui-components';
import { LEGAL } from '../../utils/routes';

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid var(--gray-200, #E2E8F0)',
    },
  },
});

const ContactDrawer = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    isContactUIOpen,
    openContactUI,
    closeContactUI,
    contactUITitle,
    contactUIDescription,
    feedback,
    onFeedbackChange,
    isLoading,
    handleContactUs,
    feedbackSubmitted,
  } = useContactUI();

  const drawerContent = isLoading ? (
    <Box my={4}>
      <Loading size="5rem" pt={0} />
    </Box>
  ) : (
    <Box role="presentation">
      <Stack gap={1}>
        <KeyopsHeader1>{contactUITitle}</KeyopsHeader1>
        <Typography
          color={theme.palette.keyops.gray[700]}
          mb={feedbackSubmitted && 5}
        >
          {contactUIDescription}
        </Typography>
      </Stack>
      {!feedbackSubmitted && (
        <>
          <CssTextField
            id="outlined-multiline-flexible"
            data-testid="feedback-textarea"
            multiline
            fullWidth
            minRows={3}
            sx={{ marginTop: 2.5 }}
            value={feedback}
            onChange={onFeedbackChange}
          />
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            sx={{ marginTop: 4.5 }}
          >
            <Box fontSize={'12px'} alignSelf={'center'}>
              <Link href={LEGAL}>Legal</Link>
            </Box>
            <Stack direction={'row'} gap={2} sx={{ float: 'right' }}>
              <Button
                variant="outlined"
                onClick={closeContactUI}
                sx={{
                  fontSize: 16,
                  textTransform: 'capitalize',
                }}
              >
                {t('general.cancel')}
              </Button>
              <Button
                variant="contained"
                disabled={!feedback}
                onClick={() => handleContactUs(feedback)}
                sx={{
                  fontSize: 16,
                  textTransform: 'capitalize',
                }}
              >
                {t('general.submit')}
              </Button>
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );

  return (
    <SwipeableDrawer
      anchor={'bottom'}
      open={isContactUIOpen}
      onOpen={openContactUI}
      onClose={closeContactUI}
      id="contact-drawer"
      sx={{
        display: { sm: 'none' },
        zIndex: 10000,
      }}
      PaperProps={{
        sx: {
          p: 2.5,
          borderRadius: '8px 8px 0 0',
        },
      }}
    >
      <FiX
        data-testid="close-icon"
        fontSize={24}
        style={{
          position: 'absolute',
          right: 5,
          top: 5,
          padding: '4px 4px 0 0',
        }}
        onClick={closeContactUI}
      />
      {drawerContent}
    </SwipeableDrawer>
  );
};

export default ContactDrawer;
