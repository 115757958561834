import React from 'react';
import { Box, Link, Stack, Typography } from '@mui/material';
import { KeyopsHeader1, KeyopsHeader2 } from '@keyops-hcp/ui-components';
import { Trans, useTranslation } from 'react-i18next';

export const Legal = () => {
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <KeyopsHeader1>{t('legal.title')}</KeyopsHeader1>
      <Box>
        <KeyopsHeader2>{t('legal.tos')}</KeyopsHeader2>
        <Typography>
          <Trans
            i18nKey="legal.tosBody"
            components={{
              TosLink: (
                <Link
                  href="https://www.keyops.io/terms-of-use"
                  target="_blank"
                />
              ),
            }}
          />
        </Typography>
      </Box>
      <Box>
        <KeyopsHeader2>{t('legal.privacy')}</KeyopsHeader2>
        <Typography>
          <Trans
            i18nKey="legal.privacyBody"
            components={{
              PrivacyLink: (
                <Link
                  href="https://www.keyops.io/privacy-policy"
                  target="_blank"
                />
              ),
            }}
          />
        </Typography>
      </Box>
      <Box>
        <KeyopsHeader2>{t('legal.attributions')}</KeyopsHeader2>
        <Typography>
          <Trans
            i18nKey="legal.novaAttribution"
            components={{
              NovaLink: (
                <Link
                  href="https://www.figma.com/community/file/964083475606904624"
                  target="_blank"
                />
              ),
              CCByLink: (
                <Link
                  href="https://creativecommons.org/licenses/by/4.0/"
                  target="_blank"
                />
              ),
            }}
          />
        </Typography>
      </Box>
    </Stack>
  );
};
