import React from 'react';

import { alpha, Theme } from '@mui/material';
import { theme } from '../theme';
import { SxProps, CSSObject } from '@mui/system';

/**
 * Default style for hovering over item component (icon, nav item,...)
 */
export const hoverItemStyle = (
  styles: SxProps<Theme> = {} // Merge in any additional styles provided
): SxProps<Theme> =>
  ({
    '&:hover': {
      backgroundColor: alpha(theme.palette.keyops.blue.arcticBlue, 0.2),
      borderRadius: '6px',
      cursor: 'pointer',
      ...styles,
    },
  } as SxProps<Theme>);

export const hoverCardStyle = (
  styles: CSSObject = {}, // Merge in any additional styles provided

  ctaStyles: CSSObject = {}
): CSSObject => ({
  '&:hover': {
    boxShadow: '0px 1px 2px #0000000D',
    border: `1px solid ${theme.palette.keyops.blue.lightSteelBlue}4D`,
    cursor: 'pointer',
    ...styles,
  },
  '&:hover .callToAction': {
    color: theme.palette.keyops.blue.midnightBlue,
    ...ctaStyles,
  },
});
