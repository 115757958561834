export function fetchUrlHashData() {
  // Fetch gd & msgId from the URL hash
  const hash = window.location.hash.substring(1); // Remove the '#' from the hash
  let gd = undefined; // General Discussion
  let msgId = undefined;

  if (hash) {
    // Check if the hash starts with 'gd.'
    if (hash.startsWith('gd.')) {
      gd = true;
      msgId = hash.substring(3); // Extract the part after 'gd.'
    } else {
      msgId = hash; // Handle #msgId format
    }
  }

  return { gd, msgId };
}

export function scrollToMsgId(msgId: string) {
  const element = document.getElementById(msgId);
  // Scroll to the element if it's present
  if (element) {
    setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (window.history.replaceState) {
        window.history.replaceState(null, null, window.location.pathname);
      }
    }, 750);
  }
}
