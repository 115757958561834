import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Stack, Box, Typography, Button } from '@mui/material';
import { FiChevronLeft } from 'react-icons/fi';
import { AdBoardSectionTaskInfo } from '../AdBoardSectionTaskInfo';
import i18next from '../../languages/i18n.config';
import { DiscussionMessage } from '../DiscussionMessage';
import { useOneDiscussion } from '../../hooks/useDiscussion';
import { DiscussionMessageForm } from '../DiscussionMessageForm';
import { fetchUrlHashData, scrollToMsgId } from '../../utils/discussion';

export const TopicDisplay = ({
  topicId,
  setIsFullScreenData,
  sectionNavigate,
}: {
  topicId: string;
  setIsFullScreenData: (isFullScreen: boolean) => void;
  sectionNavigate: (contentId?: string) => void;
}) => {
  const location = useLocation();
  const { data: content } = useOneDiscussion(topicId); //TODO: add loading + error ui state
  const handleBackClick = () => {
    sectionNavigate();
    setIsFullScreenData(false);
  };

  const { msgId } = fetchUrlHashData();

  useEffect(() => {
    if (msgId) {
      scrollToMsgId(msgId);
    }
  }, [msgId, location]);

  return (
    <Stack spacing={2}>
      <Button
        aria-label='back_to_topics'
        startIcon={<FiChevronLeft />}
        onClick={handleBackClick}
      >
        {i18next.t('ad_board.discussion.back_to_topics')}
      </Button>
      <Typography fontWeight={400} fontSize='16px' lineHeight='24px'>
        {content?.topic}
      </Typography>
      <AdBoardSectionTaskInfo>
        {i18next.t('ad_board.discussion.objectives')}
      </AdBoardSectionTaskInfo>
      <DiscussionMessageForm discussionId={topicId} />
      {/* Use prev component here! */}
      <Stack direction='column' gap={2}>
        {content?.messages?.map((message) => (
          <Box
            key={message.id}
            display='flex'
            alignItems='center'
            border='1px solid'
            borderColor='#7C8DB533' // Light steel blue, needs to stay here for custom color
            borderRadius={1}
            p={3}
            pb={0}
          >
            <DiscussionMessage
              key={message?.id}
              highlighted={msgId === message?.id}
              message={message}
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};
