import React from 'react';
import { Link, LinkProps } from '@mui/material';
import { useTheme } from '@mui/system';

export const PrimaryLink = ({ href, children, ...props }: LinkProps) => {
  const { palette } = useTheme();

  return (
    <Link
      href={href}
      sx={{
        color: props.color ?? palette.keyops.blue.keyopsBlue,
        textTransform: 'none',
        textDecoration: 'none',
        '&:hover': {
          color: palette.keyops.blue.midnightBlue,
        },
      }}
      {...props}
    >
      {children}
    </Link>
  );
};
