import React from 'react';
import { useTheme } from '@mui/material';

import { RightArrow } from '../../icons';
import { getFormattedDatesForRange } from '../../utils/datetime';
import i18next from '../../languages/i18n.config';

export const DateRange = ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  const theme = useTheme();
  const { formattedStartDate, formattedEndDate } = getFormattedDatesForRange(
    i18next.language,
    startDate,
    endDate
  );
  if (!formattedStartDate || !formattedEndDate) {
    return <></>;
  }
  return (
    <span className='textWithIcons'>
      {formattedStartDate}
      <RightArrow
        sx={{
          px: '4px',
          fontSize: '17px',
          color: theme.palette.keyops.blue.lightSteelBlue,
        }}
      />
      {formattedEndDate}
    </span>
  );
};
