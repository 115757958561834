import i18n from '../languages/i18n.config';

const intervalKeyMappings = new Map([
  [31536000, 'years'], //365 days
  [2592000, 'months'], //30 days
  [86400, 'days'],
  [3600, 'hours'],
  [60, 'minutes'],
]);
export const getTimeSince = (date: Date): string => {
  const secondsDiff = Math.floor(
    (new Date().getTime() - new Date(date).getTime()) / 1000
  );

  for (const [key, value] of Array.from(intervalKeyMappings)) {
    if (secondsDiff >= key) {
      return i18n.t('since.' + value, { count: Math.floor(secondsDiff / key) });
    }
  }
  //fall back to seconds
  return i18n.t('since.seconds', { count: secondsDiff });
};

export const getVideoTime = (seconds: number): string => {
  const dateString = new Date(seconds * 1000).toISOString();
  if (seconds < 600) {
    //if it's less than 10 mins, we drop the hour part and first minute, and only show M:SS
    return dateString.substring(15, 19);
  }
  if (seconds < 3600) {
    //if it's less than an hour, we drop the hour part, and only show MM:SS
    return dateString.substring(14, 19);
  }
  if (seconds < 36000) {
    //if it's less than 10 hours, we drop the leading hour digit, and only show H:MM:SS
    return dateString.substring(12, 19);
  }
  //Otherwise show HH:MM:SS
  return dateString.substring(11, 19);
};

export const getFormattedDatesForRange = (
  language: string,
  startDateStr?: string,
  endDateStr?: string
): { formattedStartDate?: string; formattedEndDate?: string } => {
  if (!startDateStr || !endDateStr) {
    return {};
  }
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  //only include year if start date and end date are different years
  const startDateYearFormat =
    startDate.getFullYear() !== endDate.getFullYear() ? 'numeric' : undefined;

  const formattedStartDate = startDate.toLocaleDateString(language, {
    month: 'short',
    day: 'numeric',
    year: startDateYearFormat,
  });
  const formattedEndDate = endDate.toLocaleDateString(language, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return { formattedStartDate, formattedEndDate };
};

//TODO: unused?
export const formatDateRange = (startDate?: string, endDate?: string) => {
  // Convert inputs to Date objects
  const startDateObj = startDate ? new Date(startDate) : null;
  const endDateObj = endDate ? new Date(endDate) : null;

  // Check if dates are valid before proceeding
  const startTime =
    startDateObj instanceof Date && !isNaN(startDateObj.getTime())
      ? startDateObj.getTime()
      : NaN;
  const endTime =
    endDateObj instanceof Date && !isNaN(endDateObj.getTime())
      ? endDateObj.getTime()
      : NaN;

  // Helper function to format a valid date
  const formatDate = (date: Date) =>
    date.toLocaleString('en-US', { month: 'short', day: 'numeric' });

  // Handle cases where both or either date is invalid
  if (isNaN(startTime) && isNaN(endTime)) return 'N/A';
  if (!isNaN(startTime) && isNaN(endTime))
    return `Starts ${formatDate(startDateObj!)}`;
  if (isNaN(startTime) && !isNaN(endTime))
    return `Ends ${formatDate(endDateObj!)}`;

  // Both dates are valid, format them
  const formattedStart = formatDate(startDateObj!);
  const formattedEnd = formatDate(endDateObj!);

  // If both dates are in the same month
  if (startDateObj!.getMonth() === endDateObj!.getMonth()) {
    return `${formattedStart} to ${endDateObj!.toLocaleString('en-US', {
      day: 'numeric',
    })}`;
  }

  return `${formattedStart} to ${formattedEnd}`;
};

// TODO: adapt this to handle locale lang
export const formatDateWithSuffix = (date: string) => {
  const dateObj = new Date(date);
  // Check if the date is invalid
  if (isNaN(dateObj.getTime())) {
    return 'Invalid Date';
  }
  const formattedDate = dateObj.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
  });
  const day = dateObj.getDate();

  const getDaySuffix = (day: number) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const daySuffix = getDaySuffix(day);

  return `${formattedDate}${daySuffix}`;
};

export const formatSecondsDuration = (seconds: number): string => {
  if (seconds < 0) {
    throw new Error('Seconds cannot be negative.');
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    if (minutes > 0) {
      return `${hours} h ${minutes} min`;
    }
    return `${hours} h`;
  } else if (minutes > 0) {
    if (remainingSeconds > 0) {
      return `${minutes} min ${remainingSeconds} sec`;
    }
    return `${minutes} min`;
  }
  return `${remainingSeconds} sec`;
};

// Used in Chat to display the time when a message was sent
export const getMinutesAgoFromString = (sentDateString: string): string => {
  const sentDate = new Date(sentDateString);
  const now = new Date();
  const diffMilliseconds = now.getTime() - sentDate.getTime();

  // First, calculate total difference in days, hours, and minutes
  const diffDays = Math.floor(diffMilliseconds / (3600000 * 24)); // total days
  const diffMinutes = Math.floor((diffMilliseconds % 3600000) / 60000); // remainder in minutes
  const diffHours = Math.floor(diffMilliseconds / 3600000); // total hours
  const diffWeeks = Math.floor(diffDays / 7);

  // Calculate the remaining hours after accounting for full days
  const remainingHours = diffHours % 24;

  if (diffMinutes === 0 && remainingHours === 0 && diffDays === 0) {
    return `Just now.`;
  }

  // Check if the message was sent the same day
  if (diffDays < 1) {
    if (sentDate.toDateString() === now.toDateString()) {
      const hours = sentDate.getHours();
      const minutes = sentDate.getMinutes();
      const formattedTime =
        (hours % 12 || 12) +
        ':' +
        (minutes < 10 ? '0' : '') +
        minutes +
        ' ' +
        (hours < 12 ? 'AM.' : 'PM.');
      return formattedTime;
    }
    return `${diffHours}h ago.`;
  }
  // Check if the message was sent more than a day ago but less than a week ago
  else if (diffDays < 7) {
    return `${diffDays}d ago.`;
  }
  // If the message was sent a week or more ago
  return `${diffWeeks}w ago.`;
};

export const dateFormatter = (timestamp?: string): string | null => {
  if (!timestamp) {
    return null;
  }
  return new Date(timestamp).toLocaleString(['en-CA'], {
    formatMatcher: 'best fit',
    dateStyle: 'medium',
  });
};

export const timeFormatter = (timestamp?: string): string | null => {
  if (!timestamp) {
    return null;
  }

  return new Date(timestamp)
    .toLocaleTimeString('en-CA', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Ensures AM/PM format
    })
    .replace(/\.\s?/g, '');
};
